<template>
  <div class="d-flex flex-column flex-row-fluid wrapper mr-lg-10 mr-sm-0">
    <div class="content d-flex flex-column flex-column-fluid p-0">
      <div class="d-flex flex-column-fluid">
        <div class="container-fluid mt-4">
          <router-view />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/controller/Footer.component";

export default {
  name: "AdminDashboard",
  components: {
    Footer
  },
  data() {
    return {};
  }
};
</script>
